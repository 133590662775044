import React from "react";
import Layout from "../components/layout";
import Icon, { Icons } from "../components/icons";
import styled from "styled-components";

import { setLocale, setSession, setOldSession } from "../utils/localStorage";

const test = () => {
  setLocale("nb");
  setSession(false);
  setOldSession(false);

  if (typeof window === "undefined" || typeof document === "undefined") {
    return null;
  }

  const Sizes = ["XL", "Large", "Medium", "Small"];

  const PageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 3rem;
    flex-wrap: wrap;
    justify-content: center;
    padding: 5rem;
  `;

  const IconsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-content: flex-end;
    gap: 2rem;
    border: 2px solid #242426;
    position: relative;
    color: #242426;
  `;

  const Label = styled.span`
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    color: #242426;
    font-size: 1.6rem;
  `;

  window.location.href = `${window.location.protocol}//${window.location.host}`;

  return (
    <Layout>
      <PageWrapper>
        {Object.keys(Icons).map((icon) => {
          return (
            <IconsWrapper>
              <Label>Icon {icon}</Label>
              {Sizes.map((size) => {
                return <Icon icon={icon} size={size} />;
              })}
            </IconsWrapper>
          );
        })}
      </PageWrapper>
    </Layout>
  );
};

export default test;
